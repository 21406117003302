<template>
  <div>
    <CrudIndex
      v-bind="this.$store.state.stone_model.shop_class"
      @custom-table-action="$_onCustomTableAction($event)"
    ></CrudIndex>
    <WsDialogBelongsToMany
      ref="WsDialogBelongsToMany"
      :belongsToManyModelName="$store.state.stone_model.shop_product.modelName"
      :belongsToManyFields="$store.state.stone_model.shop_product.fields"
      :belongsToManyShowFields="belongsToMany.showFields"
      :belongsToManyPickShowFields="belongsToMany.pickShowFields"
      :belongsToManyPickFilterSelects="_belongsToManyPickFilterSelects"
      :modelName="$store.state.stone_model.shop_class.modelName"
      :id="belongsToMany.id"
      :label="belongsToMany.label"
      paramsKey="shop_classes"
      updateBtnLabel="管理商品"
    ></WsDialogBelongsToMany>
  </div>
</template>

<script>
export default {
  methods: {
    $_onCustomTableAction($event) {
      if ($event.emit == "shop_product") {
        this.belongsToMany.id = $event.data.item.id;
        this.belongsToMany.label = `商品分類 - ${$event.data.item.name}`;
        setTimeout(() => {
          this.$refs.WsDialogBelongsToMany.open();
        }, 0);
      }
    },
  },
  data() {
    return {
      belongsToMany: {
        id: null,
        label: null,
        showFields: ["no", "name", "spec"],
        pickShowFields: ["no", "name", "spec", "is_active", "price"],
      },
    };
  },
  computed: {
    _belongsToManyPickFilterSelects() {
      const _belongsToManyPickFilterSelects = {};
      _belongsToManyPickFilterSelects.featured_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.featured_classes;
      _belongsToManyPickFilterSelects.shop_classes =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_classes;
      _belongsToManyPickFilterSelects.shop_subclasses =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.shop_subclasses;
      _belongsToManyPickFilterSelects.is_active =
        this.$store.state.stone_model.shop_product.crudSetting.index.filterSelects.is_active;
      return _belongsToManyPickFilterSelects;
    },
  },
};
</script>